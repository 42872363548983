import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/Button"
import { Link } from "gatsby"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Tentang Kami" />
      <div className="container mx-4 lg:mx-auto my-12 px-8">
        <p className="text-3xl lg:text-5xl font-bold text-blue-700">
          Tentang Kami
        </p>
        <div className="m-4 lg:m-12 text-justify lg:text-left flex flex-col justify-center items-center">
          <div className="w-full lg:w-11/12 mb-4 lg:mb-8">
            <p className="text-lg lg:text-xl font-semibold mb-2 lg:mb-4">
              TENTANG ASTRA GREEN STUDENT INNOVATION
            </p>
            <p className="mb-1 lg:mb-2 mx-4 lg:mx-auto">
              Astra Green Student Innovation (AGEn SI) merupakan kegiatan besar
              yang dilakukan secara berkala oleh Astra Indonesia. Pada tahun
              2021, Astra menggandeng Society of Renewable Energy untuk
              berkolaborasi mewujudkan acara ini.
            </p>
            <p className="mb-1 lg:mb-2 mx-4 lg:mx-auto">
              AGEn SI merupakan kegiatan besar berupa perlombaan yang melibatkan
              mahasiswa dan SMA/SMK/MA dan sederajat seluruh indonesia untuk
              memecahkan masalah - masalah yang ada di sekitar lingkungan.
            </p>
            <p className="mb-1 lg:mb-2 mx-4 lg:mx-auto">
              Adanya AGEn SI ini diharapkan dapat menemukan ide - ide inovasi
              untuk keberlanjutan ketahanan energi di Indonesia dari berbagai
              pihak. Tidak hanya menemukan, tapi dapat mengkomunikasikannya
              kepada pihak terkait.
            </p>
          </div>
          <div className="lg:w-11/12 mb-4 lg:mb-8">
            <p className="text-lg lg:text-xl font-semibold mb-2 lg:mb-4">
              LATAR BELAKANG
            </p>
            <p className="mb-1 lg:mb-2 mx-4 lg:mx-auto">
              Indonesia adalah sebuah negara yang memiliki wilayah luas dan
              jumlah penduduk yang tinggi. Sebagai negara dengan kondisi
              geografis beragam dan penduduk yang tersebar, Indonesia masih
              menghadapi tantangan pemenuhan energi untuk semua warganya. Di
              tahun 2016, masih terdapat 7 juta rumah tangga atau sekitar 28
              juta penduduk Indonesia yang belum mendapatkan akses listrik.
            </p>
            <p className="mb-1 lg:mb-2 mx-4 lg:mx-auto">
              Di luar listrik, tantangan energi di Indonesia mencakup
              terbatasnya akses pada energi untuk menjalankan aktivitas
              sehari-hari seperti memasak. Banyak penduduk Indonesia yang tidak
              memiliki akses pada bahan bakar dan menggunakan tungku tradisional
              untuk memasak. Selain tidak tersedia sepanjang waktu, asap
              pembakaran tungku ini juga menyebabkan gangguan kesehatan pada ibu
              dan anak-anak. Di sektor lain, terbatasnya akses pada energi
              berperan pada terhambatnya perkembangan ekonomi dan pendidikan.
            </p>
            <p className="mb-1 lg:mb-2 mx-4 lg:mx-auto">
              Sementara itu, Indonesia memiliki potensi energi bersih dan
              terbarukan yang tinggi. Berdasarkan data dari Kementerian Energi
              dan Sumber Daya Mineral (KESDM), Indonesia memiliki potensi sumber
              daya energi terbarukan yang luar biasa besarnya. Potensi
              sumberdaya ini dapat dimanfaatkan untuk menciptakan akses energi
              secara merata dan bersih. Meski begitu, saat ini penggunaan energi
              bersih dan terbarukan di Indonesia baru mencapai kisaran 6% dari
              bauran energi nasional. Dengan melihat potensi energi terbarukan
              yang tersebar, penyediaan energi dalam negeri terutama di
              daerah-daerah yang belum memiliki akses terhadap energi bisa
              dipenuhi dengan potensi-potensi lokal.
            </p>
            <p className="mb-1 lg:mb-2 mx-4 lg:mx-auto">
              Menurut Survei Sosial Ekonomi Nasional (Susenas) Tahun 2019,
              perkiraan jumlah pemuda berusia 16 - 30 tahun sebesar 64,19 juta
              jiwa atau seperempat dari total penduduk Indonesia. Pada tahun
              2019, sebanyak 53,89% pemuda Indonesia memiliki pekerjaan. Saat
              situasi pandemi, angka tersebut menurun tajam karena sulitnya
              mendapatkan lapangan pekerjaan.
            </p>
            <p className="mb-1 lg:mb-2 mx-4 lg:mx-auto">
              Maka dari itu, dalam proposal acara Astra Green Energy Student
              Innovation (AGEn SI), Yayasan Indonesia Berbagi Energi mengajak
              Astra untuk berkolaborasi. Dalam acara ini, diharapkan mahasiswa
              mampu memberikan gagasan yang tepat guna dan bisa terjun langsung
              ke lapangan untuk menciptakan lapangan pekerjaan dengan gagasan
              yang ditransformasi menjadi sebuah produk atau bisnis.
            </p>
          </div>
          <div className="lg:w-11/12 mb-4 lg:mb-8">
            <p className="text-lg lg:text-xl font-semibold mb-2 lg:mb-4">
              TENTANG TEMA
            </p>
            <p className="mb-1 lg:mb-2 mx-4 lg:mx-auto">
              “Inovasi Efisiensi Energi pada Sektor Energi Baru dan Terbarukan
              (EBT) untuk Rural dan Urban Electrification”
            </p>
            <p className="mb-1 lg:mb-2 mx-4 lg:mx-auto">
              Aktivitas pertumbuhan EBT tidak akan merata jika hanya di pulau
              tertentu, sehingga daerah rural dan urban juga harus bisa
              memfungsikan EBT dengan baik.
            </p>
          </div>
          <div className="lg:w-11/12 mb-4 lg:mb-8">
            <p className="text-lg lg:text-xl font-semibold mb-2 lg:mb-4">
              A. Ketentuan Umum
            </p>
            <ul className="list-disc mx-8">
              <li>
                Inovasi yang didaftarkan harus sesuai dengan tema dan fokus
                Astra Green Energy Student Innovation.
              </li>
              <li>
                Setiap kelompok hanya diperbolehkan mengirimkan 1 (satu) Judul
                Inovasi yang dilengkapi oleh dokumen pendukung.
              </li>
              <li>
                Setiap Inovasi merupakan buatan sendiri dan bukan merupakan
                duplikat (plagiat) dari orang lain.
              </li>
              <li>
                Setiap Inovasi yang didaftarkan belum pernah dikomersialisasikan
                dan diikutsertakan dalam lomba sejenis di tingkat nasional
                maupun internasional.
              </li>
              <li>
                Judul yang digunakan selama project Inovasi berlangsung tidak
                boleh berganti.
              </li>
              <li>
                Seluruh materi yang disampaikan oleh peserta untuk kepentingan
                penjurian akan dijaga kerahasiaannya dan menjadi hak milik PT
                Astra International Tbk.
              </li>
              <li>
                Peserta yang telah mendaftar wajib membaca dan mematuhi seluruh
                persyaratan dan peraturan dalam Astra Green Energy Student
                Innovation ini.
              </li>
              <li>
                Jika ada persyaratan / ketentuan yang tidak dipenuhi oleh
                peserta, maka panitia berhak menolak dan bahkan melakukan
                diskualifikasi jika dirasa perlu.
              </li>
              <li>
                Keputusan juri & panitia bersifat mutlak dan tidak dapat
                diganggu gugat.
              </li>
            </ul>
          </div>
          <div className="lg:w-11/12 mb-4 lg:mb-8">
            <p className="text-lg lg:text-xl font-semibold mb-2 lg:mb-4">
              B. Persyaratan Peserta
            </p>
            <ul className="list-disc mx-8">
              <li>
                Peserta Astra Green Energy Student Innovation harus terdaftar
                sebagai mahasiswa D3 atau S1 aktif (untuk kategori Perguruan
                Tinggi) selama project berlangsung.
              </li>
              <li>
                Peserta merupakan kelompok siswa / mahasiswa yang terdiri dari
                minimal 2 orang dan maksimal 3 orang yang berasal dari satu
                sekolah / universitas (Minimal 1 orang harus terdaftar sebagai
                siswa / mahasiswa aktif saat acara Final Presentation
                berlangsung).
              </li>
              <li>
                Setiap kelompok boleh terdiri dari berbagai macam jurusan atau
                program studi.
              </li>
              <li>
                Peserta yang sedang mendapatkan sanksi akademik tidak
                diperkenankan mengikuti kompetisi.
              </li>
              <li>
                Setiap tim harus didampingi oleh satu atau lebih Guru / Dosen
                pembimbing dalam pembuatan Inovasi.
              </li>
              <li>
                Setiap peserta harus harus mengisi formulir pendaftaran secara
                online di bit.ly/AGEnSI2021
              </li>
              <li>
                Minimal 1 orang harus mengikuti salah satu kegiatan Road to
                Astra Green Energy Student Innovation (disertakan bukti
                mengikuti kegiatan.{" "}
              </li>
              <li>
                Peserta wajib mengunggah twibbon yang telah disediakan pada akun
                instagram pribadi dan mention ke akun instagram (@sre.nasional,
                @satu_indonesia, @pranaraksa.astra) dan tag 5 orang teman
              </li>
              <li>
                Peserta wajib me-repost postingan poster Astra Green Energy
                Student Innovation 2021 di instagram story.
              </li>
            </ul>
          </div>
          <div className="lg:w-11/12 mb-4 lg:mb-8">
            <p className="text-lg lg:text-xl font-semibold mb-2 lg:mb-4">
              C. Mekanisme Pendaftaran
            </p>
            <ul className="list-disc mx-8">
              <li>
                Peserta dapat mendaftarkan diri dengan cara mengisi form
                pendaftaran dan mengunggah persyaratan yang dibutuhkan pada
                website astra2021.srenasional.com
              </li>
              <li>Periode pendaftaran adalah 29 Maret – 25 April 2021.</li>
              <li>Dokumen yang diperlukan saat pendaftaran:</li>
              <li>
                Form Pendaftaran yang ada pada website astra2021.srenasional.com
              </li>
              <li>
                Dokumen abstrak yang sudah diisi lengkap, boleh melampirkan
                dokumen pendukung dalam bentuk infogra atau data pendukung
                lainnya
              </li>
              <li>
                Seluruh dokumen yang diperlukan saat pendaftaran diunggah dalam
                bentuk PDF dengan maksimal ukuran 5 MB pada bit.ly/AGEnSI2021
              </li>
              <li>
                Seluruh dokumen harus dikumpulkan MAKSIMAL 25 Agustus 2021 pukul
                23.59 WIB.
              </li>
              <li>
                Setiap dokumen yang dikumpulkan melewati batas pengumpulan akan
                dikenakan pengurangan nilai.
              </li>
              <li>
                Informasi terkait dengan Astra Green Energy Student Project
                dapat dilihat pada website astra2021.srenasional.com
              </li>
            </ul>
          </div>
          <div className="flex justify-center items-center gap-8">
            <Link to="/#daftar">
              <Button className="text-white bg-gray-600 hover:bg-gray-800">Daftar Sekarang</Button>
            </Link>
            <Link to="/">
              <Button className="text-gray-600 border border-gray-600 hover:bg-gray-600 hover:text-white">Kembali</Button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
